import React, { useState } from 'react';
import { useCookies } from "react-cookie";

const Customers = () => {
  const [cookies, setCookie, removeCookie] = useCookies();

  //  TODO: Backend url to config
  var backend_url = "https://crm.bomat.fi";

  if (window.location.protocol === "file:" || window.location.hostname === "localhost") {
    backend_url = "https://localhost";
  }

  var [msg, setMsg] = useState("Kirjaudu vasemmalta");

  const loginBtn = (event) => {
    setMsg("");
    event.preventDefault();

    var formData = new FormData(event.target)
    var payload = new URLSearchParams(formData);

    fetch(backend_url + "/user/login", 
      {
      method: 'POST',
      body: payload,
      }
    )
    .then(res =>{
      var token = res.headers.get('jwt');
      setCookie("token", token, { path: "/" });
      return res;
    })
    .then(res =>{
      // Jos palvelimella tapahtuu virhe
      if (res.status === 400) {
        console.log("response.status =", res.status);
      }
    return res.json()
    })
    .then(
      (result) => {
        if (result.error) {
          console.log("Virhe", result);
          setMsg(result.message);
          removeCookie("token", { path: "/" });
          removeCookie("user", { path: "/" });
        }
        else {
            var strUser = JSON.stringify(result.data);
            setCookie("user", strUser, { path: "/" });
            setMsg("Tervetuloa " + result.data.firstname);
        }
      },
      (error) => {
        console.log("error: ", error);
      }
    )
  }

  // Get esimerkki
  /**
   
  const getData = (id, name) => {

    // TODO: muuta url
    fetch(backend_url + "/json/getitemsfront?" + new URLSearchParams({ id: id }))
        .then(res => res.json())
        .then(
          (result) => {
            if (result.error) {
              alert ("virhe: " + result)
            }
            else {
              if (Object.keys(result).length > 0) {
              }
            }
          },
          (error) => {
            console.log("error: ", error);
          }
        )
  }
  */
  const sendRequest = () => { 

      var headers = new Headers();
      headers.append('Accept', 'application/json'); 
      headers.append('Content-Type', 'application/json'); 
      headers.append('Authorization', 'Bearer ' + cookies.token);

      var url = "/v1/customer/getinvoices";

      fetch(backend_url + url, {
        method: 'GET',
        headers: headers
      })
        .then(res => res.json())
        .then(
          (result) => {
            if (result.error) {
              throw result.error.message;
            }
            console.log("HERE: ", result);
            setMsg("Here: " + result);
          },
          (error) => {
            console.log("Virhe: ", error);
            alert("Tapahtui virhe. Yritä uudelleen tai mikäli virhe toistuu soita meille numeroon 010 235 8800*");
          }
        )
        // Ottaa kiinni aiemmin heitetyn virheen
        .catch(function(error) {    
          setMsg("ERROR: " + error);
        }); 
  }

  const clearCookie = () => {
    setCookie("user", '', { path: "/" });
    setCookie("token", '', { path: "/" });
    setMsg("Kirjaudu vasemmalta")
    // window.location.reload(false);
  }
  
  return (
    <div>
     <div className="overlay" style={{ backgroundImage: `url('images/02.jpg')`, opacity: 0.25, zIndex: 0, backgroundRepeat: 'no-repeat' }}></div>
      <div className='d-none d-sm-none d-md-block mt-50'></div>
      <div className="content">
        <div className="container d-print-none mb-5">
          <div className="row">
            <div className="col-md-3 col-xs-12 mb-4">
              {!cookies.token && (
                <div>
                  <form name="login" id="login" action="/user/login" onSubmit={loginBtn}>
                    <section>
                        <label htmlFor="username">Username</label>
                        <input type="text" id="username" name="username" autoComplete="username" required="" autoFocus=""></input>
                    </section>
                    <section>
                        <label htmlFor="current-password">Password</label>
                        <input id="current-password" name="password" type="password" autoComplete="current-password" required=""></input>
                    </section>
                    <button type="submit" className="button">Kirjaudu</button>
                </form>
                <div className="mt-2 d-flex justify-content-center"><a href="/customers/password">Pyydä uusi salasana</a></div>
                </div>
              )}

              {cookies.user && typeof cookies.user !== 'undefined' && cookies.user.length > 0 && (
                <div>
                <b>Hei {cookies.user.firstname }!</b>
                { cookies.user.companyIds && cookies.user.companyIds.length > 1 && (
                  <select>
                    {cookies.user.companyIds.map((item) => (
                      <option value={item.vat_id}>{item.company_name}</option>
                    ))}
                  </select>
                )}
                
                { cookies.user.companyIds && cookies.user.companyIds.length === 1 && (
                  <p>{cookies.user.companyIds[0].company_name}</p>
                )}

                </div>
              )}



              {cookies.token && (
                <ul>
                  <li onClick={sendRequest}>Tee token haku</li>
                  <li onClick={clearCookie}>Kirjaudu ulos</li>
                </ul>
              )}
            </div>
            <div className="col-md-9 col-xs-12">
            <h2>{msg}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

const LoginPage = {
  routeProps: {
      path: '/customers/login',
      Component: Customers
  },
  name: 'Login',
}

export default LoginPage;