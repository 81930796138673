import React, { useState } from 'react';
import { useLocation } from "react-router-dom";

const Customers = () => {

  var backend_url = "https://crm.bomat.fi";

  if (window.location.protocol === "file:" || window.location.hostname === "localhost") {
    backend_url = "https://localhost";
  }

  var [msg, setMsg] = useState("");

  // Luetaan avain urlista
  const search = useLocation().search;
  const key = new URLSearchParams(search).get("key");

  var [keyField] = useState(key);
  // TÄmä sitten lähetetään backiin uuden salasanan kanssa
  // Back encryptaa sen ja käyttäjä voi kirjautua

  const sendBtn = (event) => {
    setMsg("");
    event.preventDefault();

    var formData = new FormData(event.target)
    var payload = new URLSearchParams(formData);

    fetch(backend_url + "/user/setpassword",
      {
        method: 'POST',
        body: payload,
      }
    )
      .then(res => res.json())
      .then(
        (result) => {
          console.log("Result: ", result);

          if (result.error === "true") {
            console.log("Virhe", result);
            setMsg(result.message);
          }
          else {

            setMsg(result.message);
          }
        },
        (error) => {
          console.log("error: ", error);
        }
      )
  }

  return (

    <div>
      <div className="overlay" style={{ backgroundImage: `url('images/02.jpg')`, opacity: 0.25, zIndex: 0, backgroundRepeat: 'no-repeat' }}></div>
      <div className='d-none d-sm-none d-md-block mt-50'></div>
      <div className="content">
        <div className="container d-print-none mb-5">
          <div className="row">
            <div className="col-md-3 col-xs-12 mb-4">

              <div>
                <form name="login" id="login" onSubmit={sendBtn}>
                  <section>

                    <input id="key" name="key" type="hidden" value={keyField} readOnly></input>
                    <label htmlFor="new_password">Uusi salasana</label>
                    <input type="text" id="new_password" name="new_password"></input>
                  </section>
                  <button type="submit" className="button">Vaihda salasana</button>
                </form>
                <div className="mt-2 d-flex justify-content-center"><a href="/customers/password">Pyydä uusi salasana</a></div>
              </div>


            </div>
            <div className="col-md-9 col-xs-12">
              <div>{msg}</div>


            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

const PasswordPage = {
  routeProps: {
    path: '/customers/setpassword',
    Component: Customers
  },
  name: 'Password',
}

export default PasswordPage;