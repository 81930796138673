import Front from './front'; 
import Contact from './contact';
import Products from './products'; 
import Customers from './customers'
import Login from './customers/login'
import Password from './customers/password'
import SetPassword from './customers/setpassword'
import Video from './video'
import Cookies from './cookies'

const index = [
    Front,
    Contact,
    Products,
    Customers,
    Login,
    Video,
    Cookies,
    Password,
    SetPassword
];

export default index;