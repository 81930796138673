import React, { useState } from 'react';

var backend_url = "https://crm.bomat.fi";

if (window.location.protocol === "file:" || window.location.hostname === "localhost") {
  backend_url = "https://localhost";
}

const Password_page = () => {

  var [msg, setMsg] = useState([]);
  var [setLink] = useState([]);
  const [showForm, setshowForm] = useState(true);


  const renewBtn = (event) => {
    setMsg("");
    setLink("");
    event.preventDefault();
  
    var formData = new FormData(event.target)
    var payload = new URLSearchParams(formData);
  
    fetch(backend_url + "/user/password_link", 
      {
      method: 'POST',
      body: payload,
      }
    )
    .then(res => res.json())
    .then(json =>{
      if (json.error) {
        setMsg(json.msg);
      }
      else {
        setshowForm(false);
        setLink(backend_url + "/user/setpassword?" +json.msg);
      }
    })
  
  };

  return (
    <div className="d-flex justify-content-center w-100">
      <div className="p-2 square border border-2 rounded w-50">
        <h5>Unohtunut salasana</h5>
        <p className="small">Syötä käyttäjätunnuksesi alla olevaan kenttään, niin lähetämme sinulle linkin jolla voi asettaa uuden salasanan.</p>
        <div>
              { showForm && (
              <form name="forgotten_password" id="login" action="/user/renewpassword" onSubmit={renewBtn}>
              <input type="text" name="forgotten_userid"></input>
              <input className="ms-2" type="submit" name="forgotten_submit" value="Lähetä"></input> {msg}
              </form>) }
        </div>
      </div>
    </div>
  );
}

const PasswordPage = {
  routeProps: {
      path: '/customers/password',
      Component: Password_page
  },
  name: 'Password',
}

export default PasswordPage;