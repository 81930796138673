import React from 'react';

const Video = () => (
    <div>
        <div className="overlay" style={{ backgroundImage: `url('images/05.jpg')`, opacity: 0.25, backgroundRepeat: 'no-repeat' }}></div>
        <div className="content">
            <div className="container">
                <div className="row">
                    <div className="hidden-xs col-lg-3">
                    </div>
                    <div className="col-xs-12 col-lg-9">

                        <div class="container d-print-none">
                            <div class="row">
                                <div class="col-1"></div>
                                <div class="col-10">
                                    <iframe style={{ display: `block` }} width="100%" height="500"
                                        title="Bomat Oy"
                                        referrerpolicy="no-referrer-when-downgrade"
                                        src="https://www.youtube.com/embed/ymzHAzWmEAQ"
                                        allow="fullscreen;autoplay;">
                                    </iframe>
                                </div>
                                <div class="col-1"></div>
                            </div>
                            <div class="row">
                                <div class="col-1"></div>
                                <div class="col-10"><a href="https://youtu.be/ymzHAzWmEAQ">Voit katsoa videon myös tästä linkistä</a></div>
                                <div class="col-1"></div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
);

const VideoPage = {
    routeProps: {
        path: '/video',
        Component: Video
    },
    name: 'Video',
}


export default VideoPage;

